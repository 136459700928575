import logo from "./assets/ai-playground-logo.png";
import "./App.css";

function App() {
  return (
    <div className="app">
      <img className="app-logo" src={logo} />
      <div className="app-content">
        Gamifying the learning experience with artificial intelligence.
      </div>
      <div className="contact-container">
        <div className="app-contact">
          <div className="contact">Contact:</div>maarten@aiplayground.ai
        </div>
      </div>
    </div>
  );
}

export default App;
